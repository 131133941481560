import React from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";

const ButtonLinkContainer = styled.div`
  a.btn-primary {
    padding: 0.85rem 1.75rem;
    min-width: 200px;
    color: #ffffff !important;
    border-radius: 50px;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    transform: perspective(1px) translateZ(0);
    position: relative;
    overflow: hidden;
    outline: none !important;
    border: none !important;
    white-space: nowrap;
    background: linear-gradient(
      24deg, rgb(68 230 254) 0%, rgb(162 88 202) 63%
    );
    &:focus {
      box-shadow: none !important;
    }
    &:before {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: rgba(255, 255, 255, 0.1);
      transform: scaleX(0);
      transform-origin: 100% 50%;
      transition-property: transform;
      transition-duration: 0.5s;
      transition-timing-function: ease-out;
    }

    &:hover:before,
    &:focus:before,
    &:active:before {
      transform: scaleX(1);
      transition-timing-function: cubic-bezier(0.52, 1.64, 0.37, 0.66);
    }
  }
`;

const ButtonLink = ({...props}) => {
  return (
    <ButtonLinkContainer>
      <Button
        href={props.link}
        target={props.target}
      >
        {props.text}
      </Button>
    </ButtonLinkContainer>
    
  )
}

export default ButtonLink;
