import React from "react";
import styled from "styled-components";
import { device } from "../../utils";

const MainTitle = styled.h1`
  font-size: 35px;
  color: #000;

  @media ${device.sm} {
    font-size: 50px;
  }

  @media ${device.lg} {
    font-size: 75px;
  }
`;

export default MainTitle;
