import React from "react";
import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Rebrand/Layout";

export const wrapPageElement = ({ element, props }:any) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }:any) => (
  <GlobalProvider>{element}</GlobalProvider>
);
