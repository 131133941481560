import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Box, Button } from "../../Core";
import Link from "gatsby-link";
import { Location } from '@reach/router';
import icon1 from './../../../assets/image/rebrand/svg/fb-icon.svg'
import icon2 from './../../../assets/image/rebrand/svg/tw-icon.svg'
import icon3 from './../../../assets/image/rebrand/svg/in-icon.svg'
import icon4 from './../../../assets/image/rebrand/svg/ig-icon.svg'

const UlStyled = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
  a {
    display: block;
  }
`;

const CopyRightArea = styled.div`
  padding: 10px 0;
  margin-top: 30px;
  border-top: 1px solid #fff;
  p {
    font-size: 13px;
    margin: 0 !important;
  }

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: inline-flex;

    a {
      font-size: 16px;
      transition: 0.4s;
      padding: 0 3px;
      margin: 0 2.5px;

      &:visited {
        text-decoration: none;
      }

      &:hover {
        text-decoration: none;
      }
    }
  }
`;

const StyledSpan = styled.span`
  @media (max-width: 768px) {
    display: block;
  }
`;

const Footer = () => {
    return (
        <Location>
            {({ location }) => {
                return (
                    <div className="footer-wrapper">
                        <Container>
                            <Box>
                                <Row className="justify-content-center">
                                    {
                                        <Col md="12" className="mt-5 mt-lg-0">
                                            <Row>
                                                <Col xs="6" lg="3">
                                                    <div className="mb-5 mb-lg-4">
                                                        <p>Useful links</p>
                                                        <UlStyled>
                                                            <li>
                                                                <Link to="/check-availability/">
                                                                    Get You
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/why-youfibre/">
                                                                    Home Broadband
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/business/">
                                                                    Business Broadband
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/switch/">
                                                                    Switch
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/reviews/">
                                                                    Reviews
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a href="https://support.youfibre.com/">
                                                                    Support
                                                                </a>
                                                            </li>
                                                            <li>
                                                                <Link to="/voucher/">
                                                                    Broadband Voucher Scheme
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/socialtariff/">
                                                                    Social Tariff
                                                                </Link>
                                                            </li>
                                                        </UlStyled>
                                                    </div>
                                                </Col>
                                                <Col xs="6" lg="3">
                                                    <div className="mb-5 mb-lg-4">
                                                        <p>
                                                            Company
                                                        </p>
                                                        <UlStyled>
                                                            <li>
                                                                <Link to="/why-youfibre/" >
                                                                    Why You
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/news/">
                                                                    News
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/careers/">
                                                                    Careers
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <Link to="/legal/">Legal</Link>
                                                            </li>
                                                        </UlStyled>
                                                    </div>
                                                </Col>
                                                <Col xs="12" sm={6} lg={{ span: 3, offset: 3 }}>
                                                    <div className="mb-5 mb-lg-4">
                                                        <p>
                                                            Contact
                                                        </p>
                                                        <UlStyled>
                                                            <li>
                                                                <a href="tel:03334439694">
                                                                    Sales: 0333 443 9694
                                                                </a>
                                                                <a href="tel:03308181667">
                                                                    Business: 0330 818 1667
                                                                </a>
                                                                <a href="tel:03308222222">
                                                                    Support: 0330 822 2222
                                                                </a>
                                                                <a onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('YFChat')[0].click()}>
                                                                    Chat
                                                                </a>
                                                            </li>
                                                        </UlStyled>
                                                        <a href="https://www.ispa.org.uk/" target="_blank" rel="noreferrer" className="second-footer-logo"></a>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    }
                                </Row>
                            </Box>
                            {
                                location.pathname == "/" &&
                                <img
                                    src="https://servedby.ipromote.com/ad/?src=pixel_cid&nid=4192&cid=WQ48Q79DLEX&cat=13163"
                                    width="1"
                                    height="1"
                                    border="0"
                                    alt=""
                                />
                            }
                            <CopyRightArea>
                                <Row className="align-items-center">
                                    <Col lg="8" md="8" sm="12" className="text-left mb-5 mb-sm-0">
                                        <small style={{color: '#ffffff'}}>
                                            &copy; 2023 YouFibre Limited is a company registered in England (No.12359292).<br />
                                            Our registered address is Unit H The Courtyard, Tewkesbury Business Park,<br />Tewkesbury, GL20 8GD. YouFibre Limited. All Rights Reserved.
                                        </small>
                                    </Col>
                                    <Col lg="4" md="4" sm="12" className="mb-5 text-md-right text-sm-center">
                                        <ul className="social-icons">
                                            <li>
                                                <a href="https://www.facebook.com/YouFibre/" target="_blank" rel="noreferrer">
                                                    <img src={icon1} width={30}/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://twitter.com/youfibre" target="_blank" rel="noreferrer">
                                                    <img src={icon2} width={30}/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.linkedin.com/company/youfibre/" target="_blank" rel="noreferrer">
                                                    <img src={icon3} width={30}/>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="https://www.instagram.com/youfibre/?hl=en" target="_blank">
                                                    <img src={icon4} width={30}/>
                                                </a>
                                            </li>
                                        </ul>
                                    </Col>
                                </Row>
                            </CopyRightArea>
                        </Container>
                    </div>
                )
            }}
        </Location >
    );
};

export default Footer;
