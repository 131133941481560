import styled from "styled-components";
import { device } from "../../utils";

const PageTitle = styled.h1`
  font-weight: 700;
  letter-spacing: -2.5px;
  font-size: 2.8em;
  line-height: 45px;
  margin-bottom: 16px;
  color: #000;

  @media ${device.sm} {
    font-size: 50px;
    line-height: 62px;
  }

  @media ${device.lg} {
    font-size: 50px;
    line-height: 65px;
    margin-bottom: 30px;
  }

`;

export default PageTitle;
