import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import icoYouFibre from '../../../assets/image/rebrand/svg/favicon.svg';
import icoUpload from '../../../assets/image/rebrand/svg/youfibre-upload.svg';
import API from './../../../api/api';
import linkifyHtml from 'linkify-html';
import { Col, Row } from 'react-bootstrap';
import { Lightbox } from "react-modal-image";
import { DebounceInput } from 'react-debounce-input';
import { BsFiletypePdf } from "react-icons/bs";
import { BsFiletypeTxt } from "react-icons/bs";
import { BsFiletypeMp4 } from "react-icons/bs";
import { BsFiletypeMp3 } from "react-icons/bs";

import moment from 'moment';

const Animation = styled.div`
  &, .animation {
    width: 100%;
    display: flex;
    flex-direction: column;
    position: inherit;
    margin-bottom: -9rem;
    animation: slideIn .1s cubic-bezier(0.41, 0.39, 0.53, 1.38) forwards;
  }
  @keyframes slideIn {
    from {
      margin-bottom: -9rem;
    }
    to {
      margin-bottom: 0rem;
    }
  }
`;

const YFPost = styled.div`
  width: 100%;
  padding: 5px;
  flex: 0;
  display: flex;
  gap: 15px;
  img {
    flex: 0;
    width: 40px;
    height: 40px;
    -object-fit: fill;
    object-fit: fill;
    border-radius: 200px;
    padding: 9px;
    background-color: #ddf0fc;
  }
  span {
    color: #263238;
    background-color: #ddf0fc;
    flex: 1;
    padding: 1rem;
    border-radius: 5px;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    display: block;
    word-break: break-word;
    a, a:visited, a:active {
      font-size: 1rem !important;
      text-decoration: underline;
      color: #005095;
    }
  }
  button {
    border: 2px solid #08DE9E;
    cursor: pointer;
    &:hover {
      background-color: #08DE9E;
    }
  }
  small {
    font-size: 0.7rem !important;
    font-weight: 100;
    line-height: 0.8rem;
    color: #bcc8d0;
  }
  .action {
    width: 0.8rem;
    height: 0.8rem;
    opacity: 0.5;
  }
  .files {
    margin: 0.3rem 0rem 0rem 0rem !important;
    border-radius: 5px;
    background-color: #f4f7f9 !important;;
    padding: 0rem;
    overlay: none;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-content: flex-start !important;
    justify-content: space-evenly !important;
    align-items: flex-start !important;
    .file{
      width: 130px;
      height: 130px;
      object-fit: contain;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const MyPost = styled.div`
  width: 100%;
  padding: 5px;
  flex: 0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  img {
    flex: 0;
    width: 40px;
    height: 40px;
    opacity: 0;
  }
  span, button {
    display: block;
    background-color: #c1f7e7;
    flex: 0;
    padding: 5px;
    border-radius: 5px;
    color: #263238;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    min-width: 40%;
    word-break: break-word;
    b {
      font-weight: 900;
    }
    a, a:visited, a:active {
      font-size: 1rem !important;
      text-decoration: underline;
      color: #397040;
    }
  }
  input, select {
    font-size: 0.9rem;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding: 1px 4px;
    height: 30px;
    outline: none;
    &.invalid {
     border-bottom: 1px solid red;
    }
    &:focus{
      border-radius: 5px;
    }
    &:disabled{
      border: 1px solid #d9d9d9;
    }
  }
  select {
    cursor: pointer;
    background: url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+) no-repeat 98% 50%;
    -moz-appearance: none; 
    -webkit-appearance: none; 
    appearance: none;
  }
  .loader {
    opacity: 1;
  }
  button {
    border: 1px solid #08DE9E;
    cursor: pointer;
    &:hover {
      background-color: #08DE9E;
    }
    &:disabled, &:disabled:hover {
      border: 1px solid #e1e1e1;
      background-color: #e1e1e1;
      cursor: default;
    }
  }
  small {
    font-size: 0.7rem !important;
    font-weight: 100;
    line-height: 0.8rem;
    color: #bcc8d0;
  }
  .action {
    width: 0.8rem;
    height: 0.8rem;
    opacity: 0.5;
  }
  .files {
    margin: 0rem !important;
    border-radius: 5px;
    background-color: #f4f7f9 !important;;
    padding: 0rem;
    overlay: none;
    display: flex;
    flex-direction: row !important;
    flex-wrap: wrap !important;
    align-content: flex-start !important;
    justify-content: space-evenly !important;
    align-items: flex-start !important;
    .file{
      width: 130px;
      height: 130px;
      object-fit: contain;
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const EventPost = styled.div`
  padding: 5px;
  flex: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  img {
    flex: 0;
    width: 40px;
    height: 40px;
  }
  span {
    display: block;
    background-color: #00468b;
    flex: 1;
    padding: 5px 15px;
    max-width: 80%;
    border-radius: 5px;
    color: #ffffff;
    font-size: 1rem;
    line-height: 1.5rem;
    font-weight: 100;
    text-align: center;
    flex-wrap: wrap;
    word-break: break-word;
    b {
      font-weight: 900;
    }
  }
`;

const Splitter = styled.hr`
  width: 100%;
  height: 1px;
  background-color: unset;
`;

interface Result {
  available: boolean | null;
  salesStatus: boolean | null;
  loading: boolean;
  data: Array<{ label: string; value: string; }> | null;
}

const Chat = ({messages, chatUsername, chatConversationId, chatUserId, setChatForm, buttonAction, resetFormValues = false, newMessages}) => {
  const formRef = useRef<any>(null);
  const [formValues, setFormValues] = useState({});
  const [postCode, setPostCode] = useState('');
  const [postCodeAddress, setPostCodeAddress] = useState('');
  const [invalidFields, setInvalidFields] =useState<Set<string>>(new Set());
  const [searchResult, setSearchResult] = useState<Result>({
    available: false,
    salesStatus: null,
    loading: false,
    data: null,
  });
  const [openLightbox, setOpenLightbox] = useState(false);
  const lightboxUrl = useRef<string|null>(null);

  useEffect(() => {
    setFormValues({});
    setPostCode('');
  }, [chatConversationId, resetFormValues]);

  useEffect(() => {
    postCode.length > 0
      ? loadOptionsAsync(postCode)
      : setSearchResult({
        ...searchResult,
        data: null,
      });
  }, [postCode]);

  const loadOptionsAsync = async(input) => {
    setSearchResult({
      ...searchResult,
      data: null,
      loading: true,
    });
    updateFormValues('address', 'text', null);
    try {
      const query = `/CrmModule/v1.0/db/Premise2/search?terms=${input}&fields=properties.PostalCode,properties.PostalCodeNoSpace&schemas=${process.env.GATSBY_POSTCODE_SCHEMA_ID}&page=0&size=100&sort=[{%22properties.DoorNumber%22:{%22order%22:%22asc%22}},{%22properties.SPRN%22:{%22order%22:%22asc%22}}]`;
      API.get(query)
      .then((response) => {
        const data = response.data.data.map((category) => ({
          value:category.properties.PostalCode,
          label: category.properties.FullAddress,
        }));
        setSearchResult({
          ...searchResult,
          loading: false,
          data,
        });
      })
      .catch(function (error) {
        console.error('Error fetching ZIP codes:', error);
        setSearchResult({
          ...searchResult,
          loading: false,
        });
      });
    } catch (error) { console.error('Error fetching ZIP codes:', error); }
  };

  const validateInput = (type:string, data:any) => {
    if((typeof data === 'string' && data.length === 0) || (typeof data === 'number' && data === 0)) return true;
    const regex = {
      'select': /^[a-zA-Z0-9\s\-_,.!?()/@"']+$/,
      'text': /^[a-zA-Z0-9\s\-_,.!?()/@"']+$/,
      'email': /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.+-]+\.[a-zA-Z0-9]{2,}$/,
      'number': /^(?:1000000(?:\.0{1,2})?|[0-9]{1,6}(?:\.\d{1,2})?)$/,
    };
    return regex[type].test(data);
  };

  const updateFormValues = (action, type, value) => {
    formValues[action] = value;
    const tmpInvalidFields = invalidFields;
    validateInput(type, value)?tmpInvalidFields.delete(action):tmpInvalidFields.add(action); 
    setInvalidFields(tmpInvalidFields);   
    setFormValues({...formValues});
    setChatForm(formValues);
  };

  const validateForm = (form, values) => {
    if(form){
      let tmpValues:object = form.reduce((acc: any, item: any) => {
        if (item.action !== "submit_form") {
          acc[item.action] = (values[item.action] !== "/" && values[item.action]) || null;
        }
        return acc;
      }, {});
      return !Object.values(tmpValues).some(value => value === null);
    }
  };

  const InlineSvg = ({ url, ...props }) => {
    const [svgContent, setSvgContent] = useState<string | null>(null);  
    useEffect(() => {
      const fetchSvg = async () => {
        try {
          const response = await fetch(url);
          const svgText = await response.text();
          setSvgContent(svgText);
        } catch (error) {
          console.error("Error loading SVG:", error);
        }
      };  
      fetchSvg();
    }, [url]);  
    return (
      <div {...props}
        dangerouslySetInnerHTML={{ __html: svgContent || "" }}
        aria-hidden="true"
      />
    );
  };

  const getIcon = (url:string) => {
    if(url && typeof window !== 'undefined'){
      const extension = url.split('.').pop()?.toLocaleLowerCase();
      switch(extension){
        case 'pdf': return <BsFiletypePdf className='file' onClick={() => window.open(url,'_blank')}/>; break;
        case 'txt': return <BsFiletypeTxt className='file' onClick={() => window.open(url,'_blank')}/>; break;
        case 'mp4': return <BsFiletypeMp4 className='file' onClick={() => window.open(url,'_blank')}/>; break;
        case 'mp3': return <BsFiletypeMp3 className='file' onClick={() => window.open(url,'_blank')}/>; break;
        case 'svg': return <InlineSvg className='file' url={url} onClick={() => window.open(url,'_blank')} />; break;
        default: return <img className='file' src={url} onClick={() => { lightboxUrl.current = url; setOpenLightbox(true);}} />; break;
      }
    }
  };

  const agentMessage = (item, isNew = false) => {
    return <>
      <YFPost key={item.messageId} className={`${isNew ? "animation" : ""}`}>
        <img src={icoYouFibre} />
        <Col className='p-0 m-0'>
          <div>
            {item.message && <span dangerouslySetInnerHTML={{ __html: linkifyHtml(item.message, { target: "_blank" }).replaceAll(/\r\n|\n/g, "<br />") }} />}
          </div>
          {Array.isArray(item.files) && item.files.length > 0 &&  <div className='files'>
            {item.files.map((file) => getIcon(file.url))}
          </div>}
          <Row className='justify-between w-100 p-0 m-0 mt-1'>
            <small dangerouslySetInnerHTML={{ __html: item.from }} />
            { item.createdAt && <small className='pt-1'>{moment(item.createdAt).format('HH:mm')}</small> }
          </Row>
        </Col>
      </YFPost>
      {isNew && <Splitter />}
    </>
  };

  const customerMessage = (item) => {
    return <>
      <MyPost key={item.messageId}>
        { !item.form && <>
          {item.message && <span dangerouslySetInnerHTML={{ __html: linkifyHtml(item.message, { target: "_blank" }).replaceAll(/\r\n|\n/g, "<br />") }} />}
          {Array.isArray(item.files) && item.files.length > 0 &&  <div className='files'>
            {item.files.map((file) => getIcon(file.url))}
          </div>}
          <Row className='justify-between w-100 p-0 m-0'>
          {item.from === 'Client' ? <small>Client</small> : <small dangerouslySetInnerHTML={{ __html: item.from }} /> }
          { item.createdAt && <small className='pt-1'>{moment(item.createdAt).format('HH:mm')}</small> }
          </Row></>}
        { item.form?.type === "buttons" &&  <>
          { item.form?.value.map((itm, i) => <button key={i} onClick={() => buttonAction(itm.action)} dangerouslySetInnerHTML={{ __html: itm.title }} />)}
        </>}
        { item.form?.type === "form" &&  <form ref={formRef} method='get' action='javascript:void(0);'>
          <div className='flex flex-column gap-5'>
            { item.form?.value.map((itm, i) => <>                
              { itm?.type !== 'button' ? <><div dangerouslySetInnerHTML={{ __html: itm.title }} className='mt-2' />
              { (itm?.type === 'number' || itm?.type === 'email' || itm?.type === 'text') && 
              <input
                onChange={(e) => updateFormValues(itm.action, itm?.type, e.target.value)}
                value={formValues[itm.action]}
                placeholder={itm.placeholder}
                maxLength={250}
                {...(itm?.required && { required: true })}
                {...(invalidFields.has(itm.action) && { className: 'invalid' })}
                {...(itm?.type === 'number' && { step: "0.01", min: 0, max: 1000000 })}
                type={itm?.type}
                key={i}
              />}
              { itm?.type === 'zip' && <>
                <DebounceInput
                  required
                  type="text"
                  placeholder={itm.placeholder}
                  debounceTimeout={700}
                  value={postCode}
                  onChange={e => {
                    setPostCode(e.target.value);
                    setPostCodeAddress('');
                    updateFormValues(itm.action, 'text', '/');
                  }}
                />
                {postCode && <select
                  required
                  disabled={searchResult.data === null}
                  defaultValue={itm.action}
                  value={postCodeAddress}
                  onChange={(e) => {
                    setPostCodeAddress((e.target as HTMLSelectElement).value);
                    updateFormValues(itm.action, 'select', (e.target as HTMLSelectElement).value);
                  }}
                  className={`mt-1 ${invalidFields.has(itm.action) && 'invalid'}`} key={i}>
                  <option value={''} disabled selected>{searchResult.loading ? "Loading..." : !searchResult.data ? "" : "Select your Address"}</option>
                  {searchResult.data && searchResult.data.map(item => (
                    <option value={item.label}>
                    {item.label as String}
                  </option>
                  ))}
                </select>}
                </>
              }
              { itm?.type === 'select' && <select required onChange={(e) => updateFormValues(itm.action, itm?.type, e.target.value)} defaultValue={formValues[itm.action]} value={formValues[itm.action]} key={i}>
                  <option disabled selected hidden></option>
                  {itm?.options && Object.entries(itm?.options).map(([key, title]) => (
                    <option key={key} value={key}>
                    {title as String}
                  </option>
                  ))}
                </select>
              }
              </> : 
              <button type='submit' {...(invalidFields.size > 0  ? { disabled: true } : {disabled: false})} style={{marginTop: '15px'}}
                onClick={(e) => {
                  const valid:boolean = validateForm(item.form?.value, formValues) || false;
                  invalidFields.size === 0 && buttonAction(itm.action);
                  const target = e.target as HTMLButtonElement
                  target.disabled = valid;
                }} >Submit</button>}
                
            </>)}
          </div>
        </form>}
      </MyPost>
    </>
  };

  const eventMessage = (item, isNew = false) => {
    return <>
      <EventPost key={item.messageId} className={`${isNew ? "animation" : ""}`}>
        <img src={icoYouFibre} /> {item.message && <span dangerouslySetInnerHTML={{ __html: item.message.replaceAll(/\r\n|\n/g, "<br />") }} />}
      </EventPost>
      {isNew && <Splitter />}
    </>
  };

  return (
    <>     
      {openLightbox && <Lightbox
        medium={lightboxUrl.current}
        large={lightboxUrl.current}
        showRotate={true}
        onClose={() => setOpenLightbox(false)}
      />} 
      {newMessages && <Animation>
        { newMessages.map((item, index) => <>
          { Object.keys(messages).length > 0 && <Splitter /> } 
          { item.senderType === "AGENT" && agentMessage(item) }
          { item.senderType === "CUSTOMER" && customerMessage(item) }
          { item.senderType === "EVENT" && eventMessage(item) }    
          </>         
        )}
      </Animation>}
      {messages && messages.map((item, index) => 
        <>
        { item.senderType === "AGENT" && agentMessage(item) }
        { (item.senderType === "CUSTOMER" || item.form) && customerMessage(item) }
        { item.senderType === "EVENT" && eventMessage(item) }
        { (Object.keys(messages).length - 1) !== index  && <Splitter /> }
        </>
      )}
    </>
  );
};

export default Chat;
