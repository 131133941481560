export const OdinProductSchema = {

    residential : {
        BROADBAND: {
            contractLow : {
                value: "MONTHLY",
                title: "No contract"
            } ,
            contractHigh: {
                value: "ANNUAL_18",
                title: "18 Month Fixed Price"
            }
        },
    },
    business : {
        BROADBAND: {
            contractLow : {
                value: "ANNUAL_12",
                title: "12 months"
            } ,
            contractHigh: {
                value: "ANNUAL_24",
                title: "2 Year Fixed Price"
            }
        },
        VOICE: {
            contractLow : {
                value: "ANNUAL_12",
                title: "12 months"
            },
            contractHigh: {
                value: "ANNUAL_24",
                title: "2 Year Fixed Price"
            }
        }
    },
    stageIds: {
        leadStageId: '7b2c4629-47a0-41d8-950f-d47399c9e3e9'
    },
    preorderDiscount: 10,

    /* PRODUCTION ONLY */

    // ID of the product for which free YouMesh is activated.
    specificProductId: '8e374848-6a65-4d70-8ede-04eba821a5ce',
    // ID of the default YouMesh product (with price).
    defaultYouMeshID: 'b3478938-5af4-4a51-a5cc-15d25dbf3e45',
    // ID of the free YouMesh product (100% discount).
    freeYouMeshID: '235df566-f006-408f-a423-5d3f3e7bd816',
    youPhonePlusID: '2dfa7be3-b4b1-4124-977b-054281c63609',
    youPhoneID: '0ce63f22-a4b0-49c5-bc2b-23cb154ac031'
}