import React, { useState, useContext } from "react";
import { Container } from "react-bootstrap";
import { Link } from "gatsby";
import GlobalContext from "../../../context/GlobalContext";
import { Location } from '@reach/router';

import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';

const MainMenu = (props) => {
  const gContext = useContext(GlobalContext);

  const closeMenu = () => {
    document.querySelector('body').click();
  };

  const menuItem = ({ label, isExternal = false, name, items, ...rest }, index) => 
    isExternal ? (
      <a className="nav-link" target="_blank" href={`${name}`} rel="noopener noreferrer" >{label}</a>
    ) : (
      <Location>
        {({ location }) => {
          return (items !== null) ? 
              <NavDropdown title={label} className="nav-dropdown" >{items.map((item,index) => menuItem(item, index))}</NavDropdown>:
              (name === '#chatnow') ? <a 
                      role="button"
                      aria-expanded="false"
                      className="nav-link" onClick={() => (typeof window !==  "undefined") && document.getElementsByClassName('YFChat')[0].click()}>{label}</a> :
              <Link
                to={(name !== ' ' && name !== null) ? (name !== '#')?`/${name}/`: (location.pathname) ? location.pathname+'#' : '/#' : '/'}
                disabled={name === null? 'disabled': null}
                role="button"
                aria-expanded="false"
                className="nav-link"
                onClick={() => closeMenu()}
              >{label}</Link>;
            }
        }
      </Location>
    );
  
  return (
    <Navbar className="main-menu">
      <Container>
        <Navbar.Collapse>
          <Nav>
            {props.items.map((item,index) => menuItem(item, index))}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
export default MainMenu;
