import React, { useContext } from "react";
import { Link } from "gatsby";
import youfibreLogo from "../../assets/image/rebrand/svg/yf-logo.svg";
import GlobalContext from "../../context/GlobalContext";

const Logo = ({ className = "", ...rest }) => {

  const gContext = useContext(GlobalContext)
  return (
    <Link to="/" onClick={() => { gContext.goSetRegistrationData(null) }} className={`${className}`} {...rest} >
      <img src={youfibreLogo} className="logo" alt="YouFibre Logo" />
    </Link>
  );
};

export default Logo;
