import React from "react";
import styled from "styled-components";

const ButtonStyled = styled.button`

`;

const Button = ({...rest}) => {
  return (
    <ButtonStyled
      {...rest}
    />
  )
};

export default Button;
